<template>
   <div>
    <CButton class="btn-success btn-lg" @click="showCreatePlan = true">Add New Plan</CButton>
     <CModal
       :show.sync="showCreatePlan"
        :title="edit_plan? 'Edit Plan' : 'Create new Plan'"
        size="lg"
        :centered="true"
        :footer="false"
        :closable="true"
      >
      <template #footer>
        <CButton @click="showCreatePlan = false" color="danger" style="display: none;">Close</CButton>
      </template>
    <!-- <CCard> -->
        <!-- <CCardHeader>
        <CIcon name="cil-list"/><strong>{{ edit_plan?'Edit Plan':'Create new Plan' }}</strong>
     </CCardHeader> -->
      <CCardBody class="col-md-12">
          <CRow class="col-md-12">
              <CRow class="col-md-12">
                  <label class="col-md-3">Plan Name : </label>
                  <CInput class="col-md-8" type="text" v-model="input.plan_name"/>
              </CRow>
              <CRow class="col-md-12">
                  <label class="col-md-3">Plan Type : </label>
                    <CSelect
                    class="col-md-8"
                    :options="plan_type_opt"
                    :value.sync="input.planType"
                    size="sm"
                    placeholder='select'
                    />
                    </CRow>

              <CRow class="col-md-12" v-if="input.planType == 2">
                  <label class="col-md-3">Price : </label>
                 <CInput class="col-md-8" type="number" v-model="input.price" />
              </CRow>

              <CRow class="col-md-12">
                  <label class="col-md-3">Clicks : </label>
                 <CInput class="col-md-8" type="number" v-model="input.clicks" />
              </CRow>

              <CRow class="col-md-12">
                  <label class="col-md-3">Validity : </label>
                    <CSelect
                    class="col-md-8"
                    :options="validity_opt"
                    :value.sync="input.validity"
                    size="sm"
                    placeholder='select'
                    />
              </CRow>

              <CRow class="col-md-12">
                  <label class="col-md-3">Type : </label>
                    <CSelect
                    class="col-md-8"
                    :options="type_opt"
                    :value.sync="input.type"
                    size="sm"
                    placeholder='select'
                    />
              </CRow>

              <CRow class="col-md-12" v-show="edit_plan">
                  <label class="col-md-3">Status : </label>
                    <CSelect
                    class="col-md-8"
                    :options="status_opt"
                    :value.sync="input.status"
                    size="sm"
                    placeholder='select'
                    />
              </CRow>
              <CRow class="col-md-12">
                  <label class="col-md-3">Plan TTL <small class="text-primary">(in days)</small> : </label>
                  <CInput class="col-md-5" type="number" v-model="planTTL" placeholder="Enter day count"/>
                  <span class="col-md-3" v-if="planTTL =='' || planTTL == NaN"><small class="text-info">(Default it will take 30 Days)</small></span>
              </CRow>

              <CRow class="col-md-12">
                  <CCol class="col-md-12">
                  <CButton class="col-12 btn-sm"
                        type="submit"
                        v-on:click="onSubmit()"
                        variant="" color="success"
                    >{{edit_plan? "Save" : "Submit"}}</CButton>
                  </CCol>
              </CRow>
          </CRow> 
      </CCardBody>
    <!-- </CCard> -->
</CModal>
      <CCard class="mt-3">
           <CCardHeader>
                <CIcon name="cil-list"/><strong> All Plans</strong>
           </CCardHeader>
            <CCardBody>
             <CDataTable
                :items="plan_data"
                :fields="fields"
                :dark="false"
                :items-per-page="tableperpage"
                pagination
                sorter
                hover
                striped
                border
                small
                fixed
                table-filter
                light
                >
                <template #status="{item}">
                <td>
                    <span v-if="item.status == true" class="text-success font-weight-bold">Active</span>
                    <span v-if="item.status == false" class="text-danger font-weight-bold">Inactive</span>
                </td>
                </template>
                <template #planType="{item}">
                <td>
                    <span v-if="item.planType == 1">Free</span>
                    <span v-else-if="item.planType == 2">Paid</span>
                    <span v-else-if="item.planType == 3">Internal</span>
                    <span v-else></span>
                </td>
                </template>
                <template #planTTL="{item}">
                <td>
                    <span v-if="item.planTTL">{{item.planTTL / (1000 * 60 * 60 * 24)}} days</span>
                    <span v-else>30 days</span>
                </td>
                </template>
                <template #action="{item}">
                    <td>
                    <span @click="Edit(item)">
                    <CImg src="img/logo/edit.png" class="img-fluid" title="Edit"
                                        style="cursor: pointer;"
                                        ></CImg>
                    </span>
                    <span @click="Delete(item)">
                    <CImg src="img/logo/delelt.png" class="img-fluid ml-1" title="Delete"
                    style="cursor: pointer;"
                    ></CImg>
                    </span>
                    </td>
                </template>
             </CDataTable>

             
           </CCardBody>
      </CCard>
   </div> 
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import ManagerService from '../../services/managerControlService'
export default {
    data(){
        return{
            showCreatePlan:false,
            planTTL:'',
            edit_plan:false,
            input:{plan_name:'',planType:null,price:0},
            fields:[
                { key: 'plan_name', label: 'Plan Name'},
                { key: 'planType', label: 'Plan Type'},
                { key: 'price', label: 'Price'},
                { key: 'clicks', label: 'Clicks'},
                { key: 'validity', label: 'Validity'},
                { key: 'type', label: 'Type'},
                { key: 'status', label: 'Status'},
                { key: 'planTTL', label: 'Plan TTL'},
                { key: 'action', label: 'Action'}
            ],
            tableperpage:20,
            validity_opt: [{ label: "Select One", value: null },
                            { label: "Weekly", value: 'Weekly' },
                            { label: "Monthly", value: 'Monthly' },
                            { label: "Quarterly", value: 'Quarterly' },
                            { label: "Yearly", value: 'Yearly' }],
            type_opt:[{ label: "Select One", value: null },
                        { label: "Browse", value: 'Browse' },
                        { label: "Render", value: 'Render' },
                        { label: "Impression", value: 'Impression' },
                        { label: "Conversion", value: 'converted' }],
            status_opt:[{ label: "Active", value: true },
                        { label: "Inactive", value: false }],
            plan_data:[],
            plan_type_opt:[
                {value:null,label:'select plan type'},
                {value:1,label:'Free'},
                {value:2,label:'Paid'},
                {value:3,label:'Internal'}
            ],
            product_info:[]
        }
    },
    watch:{
        showCreatePlan(val){
            if(!val){
                this.edit_plan = false
                this.input = {}
                this.planTTL = ''
            }
        }
    },
    async mounted() {
      if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
      else{
          this.getProduct()
          this.getPlans()
    }
    },
    methods: {
        async getProduct(){
            let response = await ManagerService.getProduct();
            if(response.result){
                this.product_info = response.data
            }
        },
        async Delete(data){
            if(confirm('Are you sure want to delete this plan?')){
               let response = await ManagerService.deleteplan({'planid':data._id});
               if(response.result){
                   this.getPlans()
               }
            }
        },
       async onSubmit(){
        this.input.product_id = this.product_info[0].product_id
          if(this.Validate()){
               try {
                var response
                this.input.planTTL = 1000 * 60 * 60 * 24 * this.planTTL
                if(this.edit_plan){
                let payload = {planid:this.input._id, planTobeChange: this.input}
                response = await ManagerService.editplan(payload);
                }
                else{
                response = await ManagerService.createplan(this.input);
                }
               if(response.result){
                if(this.edit_plan){
                    Swal.fire({title: 'Success!',text: "Plan updated",icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
                }
                else{
                Swal.fire({title: 'Success!',text: "Plan Created",icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
                }
                this.edit_plan = false
                this.showCreatePlan = false
                this.input = {}
                this.getPlans()
               } 
                } catch (e) {
                    console.log('connection error')
                }
                } 
               else{
                Swal.fire({title: 'Error!',text: "All details Required!",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
                } 
        },
        Validate(){
            if(this.input.plan_name && this.input.clicks  && this.input.type && this.planTTL !=''){
                return true
            }
            else{
                return false
            }
        },
        async getPlans(){
            let res = await ManagerService.getplan();
            this.plan_data = res.message
        },
        Edit(data){
            this.edit_plan = true
            this.input = data
            this.planTTL = ''
            if(data.planTTL){
                this.planTTL = data.planTTL / (1000 * 60 * 60 * 24)
            }
            this.showCreatePlan = true
        }
    }
}
</script>
